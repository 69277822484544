<template>
  <div class="lovers-wish-layout">
    <div class="user-div" v-if="!living">
      <el-tabs v-model="activateUser">
        <el-tab-pane label="登录" name="userLogin">
          <div style="padding-right: 20px;">
            <el-form label-position="right" :rules="userLoginRules" ref="userLoginForm" label-width="60px" :model="userLogin">
              <el-form-item label="账户" prop="userName">
                <el-input v-model="userLogin.userName" placeholder="请输入账户"></el-input>
              </el-form-item>
              <el-form-item label="秘钥" prop="secret">
                <el-input v-model="userLogin.secret" type="password" placeholder="请输入秘钥"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="login" :loading="userLoading">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="注册" name="userRegister">
          <div style="padding-right: 20px;">
            <el-form label-position="right" :rules="userRegisterRules" ref="userRegisterForm" label-width="60px" :model="userRegister" @submit.native.prevent>
              <el-form-item label="秘钥" prop="secret">
                <el-input v-model="userRegister.secret" type="password" placeholder="请输入秘钥"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="register" :loading="userLoading">注册</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="lovers-wish" v-if="living">
      <el-tabs v-model="activateWish">
        <el-tab-pane label="愿望抽取" name="wishExtract">
          <div>
            <div style="display: flex; justify-content: space-around;">
              <div>
                <span style="color: lightblue;">请抽取你的愿望</span>
              </div>
              <div>
                <el-button type="success" icon="el-icon-baseball" circle @click="extractWish" :disable="disableExtrace" size="small" title="抽取愿望"></el-button>
              </div>
            </div>
            <div v-if="extractWishData.value !== ''">
              <h3 >恭喜你</h3>
              <h3 style="color: red;">{{extractWishData.value}}</h3>
              <div style="display: flex; justify-content: center; font-size: small; color: cadetblue;">
                <div>该愿望由</div>
                <div style="color: blueviolet;">{{extractWishData.source}}</div>
                <div>提供</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="愿望推送" name="wishPush">
          <el-table ref="washPushTable" :data="washPushData" tooltip-effect="dark" style="width: 100%" @selection-change="handleWishPushSelect" empty-text="请添加愿望">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="value" label="愿望内容" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="effectTime" label="生效时间" show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px">
            <el-row style="margin-bottom: 12px;">
              <el-col>
                <el-input type="textarea" :rows="3"  maxlength="150" placeholder="请输入愿望内容" v-model="wishContext">
                </el-input>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 12px;">
                <el-date-picker type="date" placeholder="选择生效日期" v-model="effectTime" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </el-row>
            <el-row style="margin-bottom: 12px; display: flex; justify-content: space-between;">
              <!-- <el-button type="danger" @click="deleteWishPush">删除</el-button> -->
              <el-button type="success" @click="addWishContext">添加愿望</el-button>
            </el-row>
            <el-row style="margin-bottom: 12px;">
              <el-col :span="20">
                <el-input placeholder="请输入对方账户" v-model="pushUserName">
                </el-input>
              </el-col>
              <el-col :span="4" style="margin-top: 4px;">
                <el-button type="success" icon="el-icon-s-promotion" circle @click="pushWish" size="small" title="推送给对象"></el-button>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="愿望列表" name="wishList">
          <el-table ref="multipleTable" :data="wishListData" tooltip-effect="dark" style="width: 100%" @selection-change="handleWishListSelect" empty-text="没有待抽取的愿望">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="value" label="愿望内容" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="source" label="来源">
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px">
            <el-button @click="deleteWishList">删除</el-button>
            <el-button @click="refreshWishList">刷新</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="通知" name="extractNotice">
          <el-table ref="multipleTable" :data="extractNoticeData" tooltip-effect="dark" style="width: 100%" empty-text="没有通知">
            <el-table-column label="通知内容">
               <template slot-scope="scope">{{ scope.row.source }}抽取了你提供的{{ scope.row.value }}愿望</template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px">
            <el-button @click="clearNotice">清空</el-button>
            <el-button @click="refreshExtractNotice">刷新</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="add-wish-dialog">
      
    </div> -->
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'LoversWish',
    data() {
      return {
        userLoading: false,
        userLogin: {
          userName: '',
          secret: ''
        },
        userLoginRules: {
          userName: [{
            required: true,
            message: '请输入账户',
            trigger: 'blur'
          }],
          secret: [{
            required: true,
            message: '请输入秘钥',
            trigger: 'blur'
          }],
        },
        userRegister: {
          userName: '',
          secret: ''
        },
        userRegisterRules: {
          secret: [{
            required: true,
            message: '请输入秘钥',
            trigger: 'blur'
          }],
        },
        living: false,
        activateUser: "userLogin",
        activateWish: "wishExtract",
        deleteWashList: [],
        deleteWashPush: [],
        wishContext: '',
        effectTime: '',
        wishListData: [],
        washPushData: [],
        pushUserName: '',
        extractWishData: {
          value: "",
          source: ""
        },
        extractNoticeData: [],
        disableExtrace: false
      }
    },
    methods: {
      login() {
        const that = this;
        this.$refs.userLoginForm.validate((valid) => {
          if (valid) {
            that.userLoading = true;
            axios.post("/api/user/login", that.userLogin)
              .then((res) => {
                that.userLoading = false;
                that.$message.success(res.data);
                that.living = true;
                that.getWish();
                that.getNotice();
              })
              .catch((err) => {
                that.userLoading = false;
                that.$message.error(err.response.data);
              })
          } else {
            return false;
          }
        });
      },
      register() {
        const that = this;
        this.$refs.userRegisterForm.validate((valid) => {
          if (valid) {
            that.userLoading = true;
            axios.post("/api/user/register", that.userRegister)
              .then((res) => {
                that.userLoading = false;
                that.$alert("您的账户为：" + res.data + "，只提示一次，请注意保存。")
                that.userLogin.userName = res.data;
                that.activateUser = "userLogin";
              })
              .catch((err) => {
                that.userLoading = false;
                that.$message.error(err.response.data);
              })
          } else {
            return false;
          }
        });
      },
      handleWishListSelect(val) {
        this.deleteWashList = val;
      },
      handleWishPushSelect(val) {
        this.deleteWashPush = val;
      },
      getWish() {
        const that = this;
        axios.get("/api/lovers-wish/get-wish")
          .then((res) => {
            that.wishListData = res.data.filter((value) => {
              if (value.effectTime && value.effectTime !== "") {
                const effectTime = new Date(value.effectTime);
                const newTime = new  Date();
                return newTime.getTime()  >=  effectTime.getTime();
              }
              return true;
            });
            that.disableExtrace = false;
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
            that.disableExtrace = false;
          })
      },
      deleteWishList() {
        if (this.deleteWashList.length == 0) {
          this.$message.error("请选择删除的愿望");
          return ;
        }
        const that = this;
        axios.post("/api/lovers-wish/delete-wish", {
          loversWishList: that.deleteWashList
        })
          .then(() => {
            that.getWish();
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
          })
      },
      // deleteWishPush() {
      //   if (this.deleteWashPush.length == 0) {
      //     this.$message.error("请选择删除的愿望");
      //     return ;
      //   }
      //   console.log(this.deleteWashPush)
      // },
      addWishContext() {
        if (this.wishContext.length != 0 ) {
          this.washPushData.push({value: this.wishContext, effectTime: this.effectTime});
          this.$message.success("添加愿望成功");
          this.wishContext = "";
          this.effectTime = "";
        } else {
          this.$message.error("添加的愿望内容不能为空");
        }
      },
      pushWish() {
        if (this.washPushData.length == 0) {
          this.$message.error("推送的愿望不能为空");
          return ;
        }
        if (this.pushUserName.length == 0) {
          this.$message.error("接收人不能为空");
          return ;
        }
        const that = this;
        axios.post("/api/lovers-wish/push-wish", {
          otherUserName: that.pushUserName,
          loversWishList: that.washPushData
        })
          .then((res) => {
            that.$message.success(res.data);
            that.pushUserName = "";
            that.washPushData = [];
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
          })
      },
      refreshWishList() {
        this.getWish();
      },
      extractWish() {
        if (this.wishListData.length == 0) {
          this.$message.error("你的愿望列表为空，无法抽取");
          return;
        }
        this.disableExtrace = true;
        let index = this.randomNumber(this.wishListData.length);
        this.extractWishData = this.wishListData[index];
        const that = this;
        axios.post("/api/lovers-wish/extract-notice", that.extractWishData)
          .then((res) => {
            that.$message.success(res.data);
            axios.post("/api/lovers-wish/delete-wish", {
              loversWishList: [that.extractWishData]
            })
              .then(() => {
                that.getWish();
              })
              .catch((err) => {
                that.$message.error(err.response.data);
                if (err.response.data === "请重新登录") {
                  that.userLogin.userName = "";
                  that.userLogin.secret = "";
                  that.living = false;
                  that.activateUser = "userLogin";
                }
                that.disableExtrace = false;
              })
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
            that.disableExtrace = false;
          })
      },
      randomNumber(limit) {
        return Math.floor(Math.random()  *  limit);
      },
      getNotice() {
        const that = this;
        axios.get("/api/lovers-wish/get-notice")
          .then((res) => {
            that.extractNoticeData = res.data;
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
          })
      },
      refreshExtractNotice() {
        this.getNotice();
      },
      clearNotice() {
        const that = this;
        axios.get("/api/lovers-wish/clear-notice")
          .then(() => {
            that.getNotice();
          })
          .catch((err) => {
            that.$message.error(err.response.data);
            if (err.response.data === "请重新登录") {
              that.userLogin.userName = "";
              that.userLogin.secret = "";
              that.living = false;
              that.activateUser = "userLogin";
            }
          })
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .lovers-wish-layout {

    .user-div {
      display: flex;
      justify-content: center;
    }

    .lovers-wish {
      display: flex;
      justify-content: center;
    }
  }
</style>