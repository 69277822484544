<template>
  <div class="home">
    <el-container>
       <el-header>心愿抽</el-header>
      <el-main>
        <lovers-wish></lovers-wish>
      </el-main>
      <el-footer>
        <el-link href="http://beian.miit.gov.cn/" type="info">陕ICP备2024022622号-2</el-link>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  import loversWish from "./lovers-wish.vue"
  
  export default {
    name: 'HomePage',
    components: {
      "lovers-wish": loversWish
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .home {
    
  }
</style>